<template>
  <v-hover v-slot:default="{ hover }" open-delay="200">
    <v-card :elevation="hover ? 16 : 2">
      <v-card flat class="" @click="go()">
        <v-system-bar dense color="white" class="px-0">
          <v-spacer></v-spacer>
          <v-btn
            x-small
            tile
            :color="status2(vacancy).color"
            class="px-5 text-lowercase"
          >
            {{
              status2(vacancy).status === "Expirado" ||
              status2(vacancy).status === "expired"
                ? status2(vacancy).status
                : status2(vacancy).status
            }}
          </v-btn>
        </v-system-bar>
        <v-card-text>
          <v-list-item
            class="d-flex flex-row justify-space-between align-center px-0 my-3"
          >
            <v-list-item-avatar color="grey" size="50">
              <img
                :src="
                  vacancy.entity.logo
                    ? `${apiUrl}/images/entity/${vacancy.entity.logo}`
                    : `/avatar-whire.png`
                "
                alt="John"
              />
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item
            class="d-flex flex-row justify-space-between align-center px-0 my-3"
          >
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-medium pb-2">{{
                vacancy.title
              }}</v-list-item-title>
              <v-list-item-subtitle>
                <v-icon left small>place</v-icon>
                <span
                  style="color: #302f2f"
                  v-for="(item, index) in vacancy.place"
                  :key="index"
                  >{{ item }},&nbsp;</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <div
            style="height: 150px"
            v-html="
              vacancy.description
                ? vacancy.description.substring(0, 140) + '...'
                : ''
            "
          ></div>
        </v-card-text>
      </v-card>
      <v-divider class="mx-4" color="#000"></v-divider>
      <v-card-actions>
        <v-btn text color="secondary">
          <div>{{ vacancy.type }}</div>
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
          v-if="!vacancy.private"
          :disabled="share"
          icon
          class="mr-3"
          @click="openShareDialog()"
        >
          <v-icon small color="black" v-if="!share">mdi-share-variant</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { API_URL } from "@/api";
import { statusVacancyMixins } from "@/mixins/status";
export default {
  name: "VacancyCard",
  mixins: [statusVacancyMixins],
  props: {
    apply_cand: Boolean,
    vacancy: Object,
  },
  data: () => ({
    apiUrl: API_URL,
  }),
  methods: {
    openShareDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareVacancy", this.vacancy);
    },
    created() {
      this.share = localStorage.getItem("loginParams")
        ? localStorage.getItem("loginParams")
        : undefined;
    },
    go() {
      this.$router.push({
        path: `/candidate/candidate/vacancy/${this.vacancy.id}`,
        query: { done: this.done },
      });
      // this.$emit("view", this.vacancy);
    },
  },
};
</script>
