<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
    bottom
    origin="center center"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <a class="filtro" color="indigo" dark v-on="on">
        <v-icon left color="primary">mdi-filter-variant</v-icon>
        <span style="color: #000">{{ $t("filter") }}</span>
      </a>
      <!-- <pre>
        {{place}}
      </pre> -->
    </template>

    <v-card class="px-3 py-3">
      <div class="title pl-3">{{ $t("send_vacancy_filter") }}</div>
      <v-container fluid>
        <v-card flat max-width="300">
          <v-row>
            <!-- <pre>
             >> {{search}}
            </pre> -->
            <v-col class="12" md="12">
              <v-autocomplete
                v-model="filter.areas"
                :items="filteredItems"
                item-text="text"
                item-value="key"
                outlined
                :label="$t('academicEduarea')"
                dense
                multiple
                small-chips
                hide-details
                :search-input.sync="search"
                no-filter
                @change="changedFieldSingle('areas')"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- <pre>
              {{searchPlace}}
            </pre> -->
            <v-col class="12" md="12">
              <v-autocomplete
                v-model="filter.place"
                :items="filteredPlaces"
                item-text="text"
                item-value="key"
                outlined
                :label="'Local'"
                dense
                multiple
                small-chips
                hide-details
                :search-input.sync="searchPlace"
                no-filter
                @change="changedFieldSingle('place')"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn text class="mx-2" @click="clean()" color="primary">{{
              $t("clean")
            }}</v-btn>
            <v-btn color="primary" @click="$emit('apply', filter)">{{
              $t("search")
            }}</v-btn>
          </v-row>
        </v-card>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
export default {
  name: "vacancyFilter",
  mixins: [replaceSpecialCharsMixins],
  data: () => ({
    filter: {
      areas: undefined,
      place: undefined,
    },
    search: "",
    searchPlace: "",
  }),
  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("clearTestFilter", () => {
      // this.filter = {
      //   areas: undefined,
      //   category: undefined,
      //   positions: undefined
      // }
      this.clean();
    });
    // localStorage.getItem('filter').forEach(element => {
    //   console.log('localstorage',element);
    // })
    this.$emit("apply", this.filter);
  },
  computed: {
    ...mapGetters({
      getTrainingAreas: "library/trainingAreas",
      getCountiesMZ: "library/countiesMZ",
    }),

    // areas() {
    //   return this.getTrainingAreas(this.$i18n.locale).map((element) => {
    //     let text = element.text
    //       .normalize("NFD")
    //       .replace(/[\u0300-\u036f]/g, "");
    //     let key = element.key;
    //     return { text: text, key: key };
    //   });
    // },
    // place() {
    //   return this.getCountiesMZ.map((element) => {
    //     return element.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    //   });
    // },
    filteredItems() {
      let resultItems = [...this.getTrainingAreas(this.$i18n.locale)];
      if (this.search) {
        const text = this.replaceSpecialChars(this.search);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.text).includes(text)
        );
      }
      return resultItems;
    },
    filteredPlaces() {
      let resultItems = [...this.getCountiesMZ];
      if (this.searchPlace) {
        const text = this.replaceSpecialChars(this.searchPlace);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t).includes(text)
        );
      }
      return resultItems;
    },
  },
  methods: {
    changedFieldSingle(field) {
      if (!this.filter[field] || this.filter[field].length == 0) {
        this.filter[field] = undefined;
        this.$emit("apply", this.filter);
      }
    },
    clean() {
      this.filter = {
        areas: undefined,
        place: undefined,
      };
      this.search = "";
      this.searchPlace = "";
      this.$emit("clean", this.filter);
    },
  },
};
</script>