<template>
  <div>
    <list-private v-if="show" @view="view" />

    <v-container class="fill-heigt pt-0" fluid v-if="!show">
      <v-row>
        <v-col cols="12" md="12">
          <v-btn icon color="primary" @click="back()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <view-vacance :vacancy="vacancy" v-show="!show" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import listPrivate from "./../components/listPrivateVacancy.vue";
import viewVacance from "./../components/VacancyDetailsCard.vue";
export default {
  components: { listPrivate, viewVacance },
  name: "Home",
  data: () => ({
    vacancy: [],
    show: true,
  }),

  computed: {},

  methods: {
    view(vacancy) {
      this.vacancy = vacancy;
      this.show = false;
    },
    back() {
      this.show = !this.show;
    },
  },
};
</script>
